<template>
  <div class="leet-index-page">
    <div class="page-header">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in list" :key="index">
          <img :src="item.src" alt="" srcset="" />
          <div class="title-box" v-if="index === 1">
            <div class="title-content">
              <div class="top-title animate__animated animate__backInLeft">
                简捷物联
              </div>
              <div
                class="info-des animate__animated animate__backInLeft animate__delay-1s"
              >
                将物联网技术与数智控碳相融合<br />
                提供 <span>全生态零碳数智化解决方案</span>
              </div>
            </div>
          </div>
          <div class="title-sub-box" v-if="index === 0">
            <div
              class="title-sub-content animate__animated animate__backInLeft"
            >
              <div>让物“联”更简单<br />让数“聚”更便捷</div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="page-body">
      <div class="page-body-content">
        <div class="selection-part-one">
          <titleBar title="关于简捷"></titleBar>
          <div class="content">
            <p>
              浙江简捷物联科技有限公司秉承“简单即最佳”的理念，将物联网技术与储能相融合，为客户提供全周期分布式能源数智化解决方案。
            </p>
            <p>
              凭借深厚的技术沉淀，简捷物联自主研发了创新的物联网边缘操作系统，并以其为核心技术底座，聚焦新型储能领域，融合通讯、物联网、大数据、边缘计算、云边协同等多种优势技术，开发了适用于工商业储能的新一代能量管理系统（EMS）以及户用储能的数据采集设备与云平台、移动换电电池包管理系统、高度集中一体化的储能能量柜等产品。
            </p>
            <p>
              简捷物联通过持续创新和专业深耕，为客户提供储能/微网电站的站点监控管理、分布式能源网络运维管理及数据分析、动力/后备电池包监控管理、虚拟电厂需求侧响应聚合支撑、零碳智慧能源大脑等一系列高效可靠的解决方案，始终引领能源数智化发展的新潮流。
            </p>
          </div>
        </div>
        <!-- <div class="selection-part-two">
          <titleBar title="服务客户"></titleBar>
          <div class="content">
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-wrapper">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-wrapper">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-wrapper">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
          </div>
        </div> -->
      </div>
      <div
        class="page-full-body-content"
        style="
          background-image: url('https://cdn.lnxall.com/index-bg.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        "
      >
        <div class="selection-part-three">
          <titleBar title="公司资质"></titleBar>
          <div class="info-content">
            <div>简捷物联是国家高新技术企业</div>
            <div>浙江省专精特新企业，</div>
            <div>拥有二十余项发明专利和软件著作权</div>
          </div>
          <div class="wall-content">
            <div
              class="leftcon"
              style="
                background: url('https://cdn.lnxall.com/indexcard.png') no-repeat;
                background-size: 100% 100%;
              "
            ></div>
            <div class="rightcon">
              <div
                class="item-list"
                style="background: url('https://cdn.lnxall.com/mobile/card01.png') no-repeat;background-size: 100% 100%;"
              ></div>
              <div
                class="item-list"
                style="
                  background: url('https://cdn.lnxall.com/mobile/card02.png') no-repeat;background-size: 100% 100%;
                "
              ></div>
              <div
                class="item-list"
                style="
                  background: url('https://cdn.lnxall.com/mobile/card03.png') no-repeat;background-size: 100% 100%;
                "
              ></div>
              <div
                class="item-list"
                style="
                  background: url('https://cdn.lnxall.com/mobile/card04.png') no-repeat;background-size: 100% 100%;
                "
              ></div>
              <div
                class="item-list"
                style="
                  background: url('https://cdn.lnxall.com/mobile/card05.png') no-repeat;background-size: 100% 100%;
                "
              ></div>
              <div
                class="item-list"
                style="
                  background: url('https://cdn.lnxall.com/mobile/card06.png') no-repeat;background-size: 100% 100%;
                "
              ></div>
              <div
                class="item-list"
                style="
                  background: url('https://cdn.lnxall.com/mobile/card07.png') no-repeat;background-size: 100% 100%;
                "
              ></div>
              <div
                class="item-list"
                style="
                  background: url('https://cdn.lnxall.com/mobile/card08.png') no-repeat;background-size: 100% 100%;
                "
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from "vant";
import titleBar from "../../components/titleBar";
export default {
  name: "index-page",
  components: {
    titleBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data() {
    return {
      list: [
        {
          src: "https://cdn.lnxall.com/banner2.png",
        },
        {
          src: "https://cdn.lnxall.com/banner1.png",
        },
      ],
    };
  },
  mounted() {},
  filters: {},
  created() {},
  methods: {},
  destroyed() {},
};
</script>
<style scoped lang="less">
.leet-index-page {
  .page-header {
    background-color: #070508;
    .my-swipe {
      width: 100%;
      height: 260px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .title-box {
      position: absolute;
      left: 20px;
      right: 0;
      top: 60px;
      box-sizing: border-box;
      z-index: 99;
      .title-content {
        margin: 0 auto;
        .top-title {
          height: 70px;
          color: #ffffff;
          font-size: 24px;
          font-family: PingFangSC-Medium;
          line-height: 70px;
        }
        .info-des {
          margin-top: 10px;
          font-size: 22px;
          font-family: PingFangSC-Light;
          color: #ffffff;
          line-height: 28px;
          span {
            font-size: 24px;
            color: #ffffff;
            font-family: PingFangSC-Medium;
          }
        }
      }
    }
    .title-sub-box {
      position: absolute;
      top: 90px;
      left: 20px;
      right: 20px;
      .title-sub-content {
        height: 66px;
        color: #ffffff;
        font-size: 22px;
        letter-spacing: 1px;
        font-family: PingFangSC-Semibold;
        line-height: 66px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .page-body {
    box-sizing: border-box;
    width: 100%;
    .page-body-content {
      padding: 15px;
      box-sizing: border-box;
      .selection-part-one {
        padding: 20px 0 0 0;
        .content {
          p {
            overflow-wrap: break-word;
            color: #222222;
            font-size: 14px;
            line-height: 28px;
            text-align: left;
            margin-top: 10px;
          }
        }
      }
      .selection-part-two {
        // height: 560px;
        box-sizing: border-box;
        .content {
          margin-top: 60px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          box-sizing: border-box;
          .band-item {
            height: 108px;
            border-radius: 4px;
            background-color: white;
            box-shadow: 0px 16px 32px 0px rgb(199 205 226 / 20%);
            width: 270px;
            margin: 0 40px 30px 0;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: center;
            align-items: center;
          }
          .band-wrapper {
            height: 108px;
            border-radius: 4px;
            background-color: white;
            box-shadow: 0px 16px 32px 0px rgb(199 205 226 / 20%);
            width: 270px;
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .page-full-body-content {
      width: 100%;
      .selection-part-three {
        box-sizing: border-box;
        padding: 15px;
        text-align: center;
        .info-content {
          margin: 20px 0 20px 0;
          display: inline-block;
          overflow-wrap: break-word;
          color: #222222;
          font-size: 14px;
          line-height: 28px;
        }
        .wall-content {
          border-radius: 8px;
          box-shadow: 0px 16px 24px 0px rgb(183 188 205 / 20%);
          width: 100%;
          background-color: #fff;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          padding: 10px 0;

          .leftcon {
            box-sizing: border-box;
            width: 100px;
            height: 142px;
            box-shadow: 0px 7px 14px 0px rgb(161 163 173 / 22%);
          }
          .rightcon {
            width: calc(100% - 100px);
            flex-wrap: wrap;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            box-sizing: border-box;
            .item-list {
              width: 58px;
              height: 80px;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }
}
</style>
